.contenedorPermisos {
    background: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    overflow: auto;
    border: 1px solid black;
    max-height: 500px;
    padding-bottom: 15px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.permisos {
    display: block;
    width: max-content;
    border-right: 1px solid black;
}

.rowProceso {
    display: flex;
    flex-direction: row;
    background: white;
    align-items: center;
    border-bottom: 1px solid rgb(134, 134, 134);
}

.rowEncabezado {
    display: flex;
    flex-direction: row;
    background: rgb(104, 104, 104);
    border: 1px solid black;
    align-items: center;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: bold;
    border-left: unset;
}

.procesoEncabezado {
    background: inherit;
    display: flex;
    position: sticky;
    left: 0;
    width: 150px;
    height: 30px;
    border-right: 1px solid black;
    align-items: center;
    text-align: center;
    z-index: 2;
}

.tituloProcesoEncabezado {
    text-align: center;
    width: 100%;
    line-height: unset;
}

.accionesProcesoEncabezado {
    display: flex;
    text-align: center;
    width: 100%;
    width: -webkit-calc(100% - 150px);
    width: -moz-calc(100% - 150px);
    width: calc(100% - 150px);
}

.proceso {
    display: flex;
    position: sticky;
    left: 0;
    width: 150px;
    height: 50px;
    background: rgb(104, 104, 104);
    border-right: 1px solid black;
    color: white;
    font-weight: bold;
    align-items: center;
}
.Oculto{
    display: none;
}
.InicializaProceso{
    display: flex;
    flex-direction: row;
    background: transparent;
}
.txtProcesoInicializar{
    width: 60px;
    margin: 5px;
}
.btnInicializar{
    margin: 5px;
    border-radius: 10px;
    width: 120px;
    background: rgb(4, 156, 42);
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.btnRefresh{
    margin: 5px;
    border-radius: 10px;
    width: 120px;
    background: rgb(14, 123, 156);
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.btnInicializar:hover{
    background: rgb(50, 121, 143);
}
.btnInicializar:hover{
    background: rgb(128, 128, 128);
}
.tituloProceso {
    display: flex;
    padding-left: 5px;
    font-size: 13px;
    font-weight: bold;
    line-height: unset;
}

.accionesProceso {
    display: flex;
    flex-direction: row;
}

.tituloAccion {
    display: flex;
    background: rgb(221, 221, 221);
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
    text-align: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: unset;
}

.accion {
    height: 50px;
    border-right: 1px solid rgb(148, 148, 148);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.checkboxAccion{
    display: grid;
    align-items: center;
    cursor: pointer;
    width: 90%;
}

@media (max-width: 700px) {
    .permisos {
        width: auto;
    }
    .rowEncabezado {
        display: none;
    }
    .rowProceso {
        border: unset;
        margin-bottom: 3px;
        flex-direction: column;
        border-bottom: 1px solid rgb(119, 119, 119);
    }
    .proceso {
        width: 100%;
        height: 30px;
        align-items: center;
        border-right: unset;
    }
    .accionesProceso {
        width: 100%;
        overflow: auto;
        height: max-content;
    }
    .accion{
        display: flex;
        width: max-content;
    }
    .tituloAccion{
        font-size: 11px;
        display: grid;
        width: max-content;
        text-align: center;
        padding: unset;
        padding-left: 3px;
        padding-right: 3px;
        height: 25px;
    }
}
