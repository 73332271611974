*{
    margin: 0;
    padding: 0;
}


.contenedorMultiPartidas{
    width: 100%;
    height: auto;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tituloPartidas{
    width: auto;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    margin: 5px auto;
    width: 90%;
}
label {
    font: 1rem 'Fira Sans', sans-serif;
}
.horaLlegada{
    height: 30px;
}

.selectConcepto{
    height: auto;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
}

.cuentaContable{ 
    height: auto;
    border: 1px solid black;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 0px;}

.monto{  
    height: auto;
    border: 1px solid gray;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 0px;
    position: relative;
    text-align: right;
}

.campoDG{  
    display: flex;
    flex-direction: column;  
    width: 100%;
    max-width: 600px;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
}
.fila{  
    display: flex;
    flex-direction: row;
    width: 100%; 
}
.wrapper {
    display: grid;
    grid-template-columns: 100px 100px 150px 150px 100px;
}

.check{
    margin: auto;
}
@media (max-width: 600px) {
    .fila {
        flex-direction: column;
    }
    .wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
@media (max-width: 930px) {
    .wrapper {
        display: grid;
        grid-template-columns: 95px 95px 100px 100px 100px;
    }
}

.monto::-webkit-outer-spin-button{
    -webkit-appearance: none;
}

.monto::-webkit-inner-spin-button{
    -webkit-appearance: none;
}


.observaciones{
    width: calc(90% - 2px);
    height: auto;
    border: 1px solid black;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 0px;
}

.agrupadorInputsBotones{
    width: 100%;
    margin: 0 auto;
    /* background-color: cyan; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.botonesPartidas{
    width: 15%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 10px;
}

.buttonAdd{
    background-color: #388e3c;
    width: 25%;
}

.buttonRemove{
    background-color: #F31818;
    width: 25%;
}

.contenedorInputsPartida{
    width: 100%;
    padding-bottom: 10px;
}
@media (max-width: 600px) {
    .agrupadorInputsBotones{
        flex-direction: column;
    }
    .buttonAdd {
        width: 50%;
    }
    .buttonRemove{
        width: 50%;
    }
}

