:root {
    --anchoTareas: 150px;
    --anchoFechas: 80px;
    --anchoRowColumnas: calc(var(--anchoTareas) + (var(--anchoFechas) * 2));
    --altoCelda: 62px;
    --fontSize: 12px;

    --colorBorde: rgb(201, 201, 201);
    --colorLetraTitulos: rgb(255, 255, 255);
    --colorLetraRowPar: black;
    --colorLetraRowImpar: black;
    --colorFondoTitulos: rgb(83, 83, 83);
    --colorFondoRowPar: rgb(255, 255, 255);
    --colorFondoRowImpar: rgb(218, 218, 218);

    --colorFondoTarea: rgb(183, 252, 198);
    --colorFondoAvance: rgb(111, 184, 129);
    --colorBordeTarea: rgb(10, 131, 74);
    --ColorLetraTitulosTareas: black;

    /*
    Azul
    --colorBorde: rgb(238, 238, 238);
    --colorFondoTitulos: rgb(74, 88, 138);
    --colorFondoRowPar: rgb(199, 209, 224);
    --colorFondoRowImpar: rgb(107, 123, 180);
    --colorFondoTarea: rgb(98, 125, 224);
    --colorFondoAvance: rgb(68, 88, 158);
    --colorBordeTarea: rgb(0, 81, 255);
    --colorLetraRowPar: black;
    --colorLetraRowImpar: black;
    --colorLetraTitulos: rgb(240, 240, 240);

    Azul 2
    --colorBorde: rgb(238, 238, 238);
    --colorFondoTitulos: rgb(49, 71, 151);
    --colorFondoRowPar: rgb(240, 248, 255);
    --colorFondoRowImpar: rgb(206, 216, 255);
    --colorFondoTarea: rgb(98, 125, 224);
    --colorFondoAvance: rgb(68, 88, 158);
    --colorBordeTarea: rgb(0, 81, 255);
    --colorLetraRowPar: black;
    --colorLetraRowImpar: black;
    --colorLetraTitulos: rgb(240, 240, 240);
    */
    /*
    Verde
    --colorBorde: rgb(141, 141, 141);
    --colorLetraTitulos: rgb(255, 255, 255);
    --colorLetraRowPar: black;
    --colorLetraRowImpar: black;
    --colorFondoTitulos: rgb(11, 97, 83);
    --colorFondoRowPar: rgb(234, 240, 238);
    --colorFondoRowImpar: rgb(156, 212, 196);
    --colorFondoTarea: rgb(183, 252, 198);
    --colorFondoAvance: rgb(111, 184, 129);
    --colorBordeTarea: rgb(10, 131, 74);
    */
    /*
    Negro
    --colorBorde: rgb(141, 141, 141);
    --colorLetraTitulos: rgb(255, 255, 255);
    --colorLetraRowPar: black;
    --colorLetraRowImpar: black;
    --colorFondoTitulos: rgb(0, 0, 0);
    --colorFondoRowPar: rgb(255, 255, 255);
    --colorFondoRowImpar: rgb(218, 218, 218);
    --colorFondoTarea: rgb(240, 240, 240);
    --colorFondoAvance: rgb(129, 129, 129);
    --colorBordeTarea: rgb(65, 62, 62);
    */
}

body {
    height: 100%;
    width: 100%;
    margin: unset;
}

.Oculto {
    display: none !important;
}

.inhabil {
    background: rgba(0, 0, 0, 0.11) !important;
}

.contenedor {

    display: block;
    overflow: auto;
    font-size: var(--fontSize);
    border: solid var(--colorBorde) 1px;
    height: 100%;
    max-height: calc(100vh - 250px);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /*transform: scale(0.9);
    transform-origin: top left;*/
}

.contenedorGrafica {
    display: table;
    width: max-content;
    table-layout: fixed;
}

.row {
    position: relative;
    display: table-row;
    background: inherit;
}

.row.par {
    background: var(--colorFondoRowPar);
}

.row.impar {
    background: var(--colorFondoRowImpar);
    color: var(--colorLetraRowImpar);
}

.actividad {
    /*background: var(--colorAgrupador);
    color: white;*/
    font-weight: bold;
    padding-left: 0px !important;
}

.titulos {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    box-shadow: -1px -1px var(--colorBorde) inset !important;
    background: var(--colorFondoTitulos) !important;
    color: var(--colorLetraTitulos) !important;
    font-weight: bold;
    line-height: 18px;
}

.celda {
    height: var(--altoCelda);
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    box-shadow: -1px 0px var(--colorBorde) inset;
    background: inherit;
}

.celdaAgrupadora {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.Columnas {
    width: var(--anchoRowColumnas);
}

.tarea {
    height: var(--altoCelda);
    width: var(--anchoTareas) !important;
    max-height: var(--altoCelda);
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    line-height: 18px;
}

.inicio,
.fin {
    width: var(--anchoFechas);
}

.sticky {
    position: sticky;
    left: 0;
    z-index: 1;
}
.sTop{
    position: sticky;
}

.btnExpand {
    display: inline;
    padding: 5px;
    border-radius: 5px;
    height: 100%;
    width: 10px;
}

.descripcionTarea {
    width: 98%;
    margin: auto;
    vertical-align: middle;
    text-align: left;
    word-wrap: break-word;
    max-height: var(--altoCelda);
    padding-left: 15px;
    overflow: hidden;
}
.rectTarea,
.rectTareaProg,
.rectTareaReal {
    position: absolute;
    left: var(--anchoRowColumnas);
    width: 250px;
    background: var(--colorFondoTarea);
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    margin-top: 1px;
    height: calc(var(--altoCelda) - 2px);
    line-height: calc((var(--altoCelda)/2) + 10px);
    color: var(--ColorLetraTitulosTareas);
    box-shadow: 0px 0px 0px 2px var(--colorBordeTarea) inset;
    box-sizing: border-box;
    overflow: hidden;
}
.rectAvance {
    word-wrap: break-word;
    font-weight: bolder;
    position: absolute;
    left: var(--anchoRowColumnas);
    width: 50px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background: var(--colorFondoAvance);
    color: var(--ColorLetraTitulosTareas);
    margin-top: 1px;
    height: calc(var(--altoCelda) - 2px);
    line-height: calc((var(--altoCelda)/2) + 18px);
    box-shadow: 0px 0px 0px 2px var(--colorBordeTarea) inset;
    box-sizing: border-box;
    overflow: hidden;
}

.rectTareaProg {
    left: var(--anchoRowColumnas);
    margin-top: 1px;
    height: calc((var(--altoCelda)/2) - 1px);
    line-height: calc((var(--altoCelda)/2) - 2px);
    background: var(--colorFondoTarea);
    box-shadow: 0px 0px 0px 2px var(--colorBordeTarea) inset;
    box-sizing: border-box;
}

.rectTareaReal {
    left: var(--anchoRowColumnas);
    bottom: 1px;
    margin-top: calc(var(--altoCelda)/2);
    height: calc((var(--altoCelda)/2) - 1px);
    line-height: calc((var(--altoCelda)/2) - 2px);
    background: var(--colorFondoAvance);
    width: 350px;
    box-shadow: 0px 0px 0px 2px var(--colorBordeTarea) inset;
    box-sizing: border-box;
}

.rectTareaAgrup,
.rectTareaAgrupProg,
.rectTareaAgrupReal {
    position: absolute;
    left: 250px;
    margin-top: 18px;
    width: 250px;
    background: black;
    border-radius: 5px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    display: none;
    word-wrap: break-word;
    overflow: hidden;
}

.rectTareaAgrupProg {
    left: 400px;
    left: 700px;
    margin-top: 3px;
    height: calc((var(--altoCelda)/2) - 1px);
    background: rgb(182, 189, 212);
    border-radius: 5px 5px 0px 0px;
}

.rectTareaAgrupReal {
    left: 800px;
    margin-top: calc(var(--altoCelda)/2);
    height: calc((var(--altoCelda)/2) - 2px);
    background: rgb(176, 194, 255);
    border-radius: 0px 0px 5px 5px;
    width: 350px;
}

@media (max-width: 600px){
    .sticky {
        position: unset;
        left: unset;
    }
    .sTop {
        position: sticky !important;
        left: unset;
    }

}

@media (max-height: 600px){
    .contenedor{
        min-height: 400px;
    }
}
