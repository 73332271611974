#botonAux{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    display: flex;
    background: rgb(10, 131, 74);
    height: 40px;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    color: white;
    font-weight: bold !important;
    cursor: pointer;
}
#botonAux:hover
{
    background: rgb(5, 88, 49);
    color: white;
}
#contenedorBotonAux{
    display: flex;
    justify-items: middle;
    height: 100%;
    align-items: center;
}
