.cContenedorAccionesPartidas{
background-color: rgb(226, 226, 226);
border: 1px dotted gray;
text-align: center;
}

.cDropdownAccionesPartidas{
    cursor: pointer;
}

.cContenedorAccionesPartidas:hover .cContenedorAccionesPartidasHidden{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.cContenedorAccionesPartidasHidden{
    display: none;
}

.buttonAdjuntar{
    width: auto;
    margin: 10px auto;
    background-color: #00acc1;
    border: none;
    padding: 8px;
    border-radius: 40px;
    color: white;
    cursor: pointer;
}

.buttonAdjuntar:hover{
    filter: brightness(110%);
}
